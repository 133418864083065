import {ControllerFlowAPI} from '@wix/yoshi-flow-editor'
import {Reservee, Details} from '@wix/ambassador-table-reservations-v1-reservation/types'
import {ReservationLocation} from '@wix/ambassador-table-reservations-v1-reservation-location/types'

import {reservationsService} from '../../../services/reservationsService'
import {RequestStatus} from '../../../utils/wrapRequest'
import {goToReservationConfirmation} from '../../../utils/navigation'
import {getLogger} from '../../../utils/getLogger'
import {checkoutService} from '../../../services/checkoutService'
import {EXPERIMENTS} from '../../../utils/constants'

export const createReservation = (flowAPI: ControllerFlowAPI) => {
  return async (details: Details, reservee: Reservee, reservationLocation: ReservationLocation) => {
    const setProps = flowAPI.controllerConfig.setProps
    const logger = getLogger(flowAPI.bi!)
    const isPaymentRuleFlowEnabled = flowAPI.experiments.enabled(
      EXPERIMENTS.isPaymentRuleFlowEnabled,
    )

    try {
      setProps({submitReservationStatus: RequestStatus.LOADING})
      const finishedReservation = await reservationsService.createReservation(flowAPI, {
        details,
        reservee,
      })

      // this is the only place where can get just created reservation
      if (finishedReservation?.id) {
        logger.reservationCreated({
          isPreview: false,
          reservation: finishedReservation,
          reservee,
          reservationLocation,
        })

        if (!isPaymentRuleFlowEnabled) {
          return goToReservationConfirmation(flowAPI, finishedReservation.id)
        }

        const checkoutResponse = await checkoutService.createCheckout(
          flowAPI,
          finishedReservation.id,
          reservee,
        )

        if (!checkoutResponse) {
          throw new Error('Checkout is undefined')
        }

        const order = await checkoutService.createOrderFromCheckout(
          flowAPI,
          checkoutResponse.checkout!.id!,
        )

        if (!order) {
          throw new Error('Order is undefined')
        }

        await goToReservationConfirmation(flowAPI, finishedReservation.id, false, order.orderId)
      } else {
        throw new Error('No reservation')
      }
    } catch (err: any) {
      setProps({
        submitReservationStatus: RequestStatus.FAILED,
        apiRequestErrorDetails: err,
      })
    }
  }
}
